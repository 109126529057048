import React from 'react';
import { useParams } from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import {getUrlList, getBlog} from "./helpers/BlogProvider";
import BlogIndex from "./pages/BlogIndex";


export default () => {
  const { name } = useParams()

  try {

    if(!name) return BlogIndex;
    //console.log(validUrl("test"));

    if(validUrl(name)) {
      return getBlog(name)
    } else throw new Error("Blog Not Found")
  }
  catch (e) {
    console.log(e)
    return <div>Error: Blog Not Found</div>
  }
}

function validUrl(urlToTest) {
  let valid = false
  getUrlList().forEach(url => {
    if (url === urlToTest) {
      valid = true
    }
  })
  return valid
}