import React from "react";
import SingleVerticalWithImageAndText from "../components/features/SingleVerticalWithImageAndText";
import AnimationRevealPage from "./AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";

const blogs = require('../content/blogs.json');

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export function getAllBlogs () {
    return blogs["blogs"]
}

export function getUrlList () {
    let out = [];
    //console.log(blogs["blogs"]);
    blogs["blogs"].forEach(b => {
        out.push(b.url);
    })
    return out;
}

export function getBlog (blogUrl) {
    let blog = null;
    blogs["blogs"].forEach(b => {
        if (b.url == blogUrl)
            blog = b;
    })

    if (!blog) return <div>Error: Blog Not Found</div>

    return <AnimationRevealPage>
        <StyledHeader links={<> </>} />
        <SingleVerticalWithImageAndText
            title = {blog.title}
            content = {blog.content}
            imageUrl = {blog.imageUrl}
            videoUrl = {blog.videoUrl}
            externalLink = {blog.link}
        />
    </AnimationRevealPage>
}