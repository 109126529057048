import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Socials from "components/features/WolvesSocials.js";
//import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import TwoColLayoutWithVid from "components/features/TwoColWithTwoFeaturesVideo.js";
import TwoColLayoutWithPic from "components/features/TwoColWithTwoFeaturesPicture.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/Simplest Footer";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import styled from "styled-components";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;
const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;

export default () => (
  <AnimationRevealPage>
      <Hero
          navLinks = { <> </> }
          heading={ <Heading>A fangame based on the great <span tw="text-primary-500">MECHASSAULT</span> </Heading>}
          primaryAction={<PrimaryAction as="a" href={"https://new-day-fiction.itch.io/wolves/download/qQIyNi_jLANA9vVJD9xDasZFM5ZulqCv9lRq_44G"}>DOWNLOAD NOW!</PrimaryAction>}
      />
      <TwoColLayoutWithVid
          subheading={"About the Game"}
          heading={"Relieve the glory days of MechAssault in this fan made revival!"}
          description={"Our game is.... *insert game description*"}
          features={[
              {
                  Icon: BriefcaseIcon,
                  title: "PvE Waves",
                  description: "Fight your way through endless waves of oncoming enemies. How long can you survive?"
              },
              {
                  Icon: MoneyIcon,
                  title: "Multiplayer",
                  description: "Test your skill and endurance against other human players!"
              }
          ]}
          teamIllustrationSrc = "https://www.youtube.com/embed/r2zmxsb5S9o"
      />
    <Socials />
    <TwoColLayoutWithPic
        subheading={"About Us"}
        heading={"Meet the Team!"}
        description={"We are .... blablabla. Teamdescription. You know the drill. Below could be a list of members with a profile picture or something. to the right a team picture? Or the New Day Fiction logo I guess."}
        features={[
            {
                Icon: BriefcaseIcon,
                title: "MainDev",
                description: "We have the best professional marketing people across the globe just to work with you."
            },
            {
                Icon: MoneyIcon,
                title: "AnotherDev",
                description: "We promise to offer you the best rate we can - at par with the industry standard."
            }
        ]}
        primaryButtonText={""}
    />
      <Blog />
    <Portfolio />
      {/*<Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Here are what some of our amazing customers are saying about our marketing professionals. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      testimonials={[
          {
              imageSrc:
                  "https://pbs.twimg.com/media/FbH_ORkVUAAX_jJ?format=jpg&name=large",
              profileImageSrc:
                  "https://pbs.twimg.com/profile_images/1262793572979531781/voLWs4dp_400x400.png",
              quote:
                  "Not enough gatcha, 0/10",
              customerName: "Larsh",
              customerTitle: "professional Genshin player"
          },
          {
              imageSrc:
                  "https://images.unsplash.com/photo-1523952578875-e6bb18b26645?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
              profileImageSrc:
                  "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
              quote:
                  "These young people and their fast games. How should I keep up with all that movement?",
              customerName: "Oldbob10025",
              customerTitle: "Old guy."
          }
      ]}
      textOnLeft={true}
    />*/}
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
      description={"We've tried to answer all commonly asked questions below. If you're missing anything, do reach out to us!"}
      faqs={[
          {
              question: "Is this Free2Play?",
              answer:
                  "Yes, it is."
          },
          {
              question: "Can I produce video content?",
              answer:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
              question: "May I re-upload it on my own page?",
              answer:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          },
          {
              question: "Where can I reach you for support ?",
              answer:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
      ]}
    />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
