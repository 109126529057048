import React from "react";
import tw from "twin.macro";
import {Container as ContainerBase } from "components/misc/Layouts.js"


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <CopyrightText>
            MECHASSAULT © MICROSOFT CORPORATION. WOLVES IS CREATED UNDER MICROSOFT'S GAME CONTENT USAGE RULES USING ASSETS BASED ON THE MECHASSAULT UNIVERSE. WOLVES IS NOT ENDORSED BY OR AFFILIATED WITH MICROSOFT.
          </CopyrightText>
          <CopyrightText>
            Website based on TreactUI by Owais Khan.
            Created by IanDresarie for Wolves only.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
