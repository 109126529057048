import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
    tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144 h-80`
]);

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose text-primary-100`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

export default ({
    title = "Default Title!",
    content = "boring default content....",
    imageUrl = null,
    videoUrl = null,
    externalLink = null
                }) => {

    let image = null;
    if (imageUrl) image = <Image imageSrc={imageUrl} />;

    let video = null;
    if (videoUrl) image = <StyledResponsiveVideoEmbed url={videoUrl} background="transparent"/>;

    let link = null;
    if (externalLink) link = <a href={externalLink}>Would you like to know more?</a>

    let contentFormatted = content.split("\n");


  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>{title}</HeadingTitle>
        </HeadingInfoContainer>

        <Content>
            {image}
            {video}
            <br/>
            {contentFormatted.map((segment, index) => (
                <Description>{segment}</Description>
            ))}
            {link}
        </Content>
      </SingleColumn>
    </Container>
  );
};
